<template>
  <div class='log-api-view mt-5 px-2'>
    <b-modal ref='alert' scrollable hide-footer title='Aviso'>
      <p>A impressao do arquivo em excel esta sendo processada,
        clique no botão abaixo para acessar a página de downloads.</p>
      <router-link :to="{ name: 'RelatoriosConsultaLegal' }">
        <base-button>
          <span class="label">Acessar</span>
        </base-button>
      </router-link>
    </b-modal>

    <div class='inputs d-flex align-items-end justify-content-between mb-2'>
        <base-input-text
          class='btn-data'
          block
          v-model='filter.startDate'
          type='date'
          label='Início'
        />

        <base-input-text
          block
          class='mx-1 btn-data'
          v-model='filter.endDate'
          type='date'
          label='Final'
        />

        <base-input-text
          block
          v-model='filter.filter'
          placeholder='Pesquisar por email'
          type='text'
        />

        <base-button
          class='btn-pesquisar ml-1'
          @click='applyFilters()'
        >
          Pesquisar
        </base-button>

        <base-button
          class='mx-1 btn-limpar-filtros'
          @click='resetFilters()'
        >
          Limpar filtros
        </base-button>

        <base-button
          class='btn-export'
          @click="generateReport('logsApiSearchCasAgentxlsx')"
          :disabled="exportReport.logsApiSearchCasAgentxlsx"
        >
          <section v-if='exportReport.logsApiSearchCasAgentxlsx' class='d-flex align-items-center justify-content-center'>
            <b-spinner
              class='loading-button'
              color='#FFFFFF'
              label="Spinning">
            </b-spinner>
            <span class='ml-2'>Exportando...</span>
          </section>

          <section v-else>
            <i class='fas fa-download' />
            Exportar em XLSX
          </section>
        </base-button>

        <base-button
          class='ml-1 btn-export'
          @click="generateReport('logsApiSearchCasAgentcsv')"
          :disabled="exportReport.logsApiSearchCasAgentcsv"
        >
          <section v-if='exportReport.logsApiSearchCasAgentcsv' class='d-flex align-items-center justify-content-center'>
            <b-spinner
              class='loading-button'
              color='#FFFFFF'
              label="Spinning">
            </b-spinner>
            <span class='ml-2'>Exportando...</span>
          </section>

          <section v-else>
            <i class='fas fa-download' />
            Exportar em CSV
          </section>
        </base-button>
    </div>
    <log-api-table
      :logsAPi='logsAPi'
      :logsAPiMeta='logsAPiMeta'
      @change-logs='handleChangeLogsAPi'
    />
    <p class='text-center' v-if='logsAPi.length < 1'>Nenhum item encontrado</p>
  </div>
</template>

<script>
import LogApiTable from '@/components/LogApi/Table'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LogApi',
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      openDialogUpdateApikeyPartner: false,
      openDialogModalSeeUpdateLogs: false,
      filter: {
        startDate: '',
        endDate: '',
        filter: '',
      },
      partnerId: '',
      message: '',
      exportReport: {
        logsApiSearchCasAgentxlsx: false,
        logsApiSearchCasAgentcsv: false,
      }
    }
  },
  components: {
    LogApiTable,
  },
  computed: {
    ...mapGetters('logApi', {
      logsAPi: 'getLogsAPi',
      logsAPiMeta: 'getLogsAPiMeta'
    }),
    exportLogs(){
      return `${process.env.VUE_APP_BASE_URL}/log-api/export/${this.startDate || 'not_selected'}/${this.endDate || 'not_selected'}/${this.filter || 'not_selected'}`
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
  watch: {
  },
  methods: {
    ...mapActions('logApi', {
      handleGetLogsAPi: 'handleGetLogsAPi',
      resetLogsAPi: 'resetLogsAPi',
    }),
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    async applyFilters(){
      await this.handleGetLogsAPi(this.filter)
    },
    async resetFilters(){
      this.filter.startDate = '';
      this.filter.endDate = '';
      this.filter.filter = '';
      this.resetLogsAPi()
      await this.handleGetLogsAPi()
    },
    getUpdateLog(index) {
      this.handleGetUpdateLogByIndex(index)
      this.openDialog = true
    },

    async handleChangeLogsAPi(payload) {
      const {startDate, endDate} = payload;
      this.startDate = startDate
      this.endDate = endDate
      const params = {
        ...this.logsAPiMeta,
        ...payload
      }
      await this.handleGetLogsAPi(params)
    },

    async generateReport(downloadType) {
      this.exportReport[downloadType] = true
      const { startDate, endDate, filter } = this.filter
      const payload = {
        user: this.user.id,
        type: downloadType,
        format: downloadType === 'logsApiSearchCasAgentxlsx' ? 'xlsx' : 'csv',
        job: 'logsApiSearchCasAgentReport',
        parameters: {
          startDate: startDate,
          endDate: endDate,
          filter: filter
        }
      }
      const { data, status } = await this.handleCreateReportDownloads(payload)

      let message = data.message
      let variant = 'danger'
      this.exportReport[downloadType] = false
      if(status === 201){
        message = data.message;
        variant = 'success';
        this.$refs['alert'].show()
        return
      }
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });
    },
  },
  async created() {

    await this.handleGetLogsAPi({
      sortIn: 'desc'
    })
  }
}
</script>

<style lang='scss' scoped>
.inputs {
  .btn-data {
    max-width: 155px;
  }

  .btn-pesquisar {
    min-width: 105px;
    height: 48px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }

  .link-export {
    text-decoration: none;
  }

  .btn-export {
    min-width: 190px;
    height: 48px;
  }
}

.log-api-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.search {
  display: flex;
  justify-content:flex-end;
  gap: 4px;

  /deep/ input {
    width: 256px;
  }
}
i {
  cursor: pointer;

  &:hover(:not(.fa-ban)) {
    opacity: 0.95;
  }
}
</style>
